import React from 'react'
import { Avatar, Item, Token, VStack } from '@revolut/ui-kit'
import { unlinkContactHrEmailAccount } from '@src/api/settings'
import { LogoGoogle, LogoMicrosoft } from '@revolut/icons'
import { useGetEmployeePersonalSettings } from '@src/api/employees'
import {
  askGoogleEmailPermissions,
  askMicrosoftEmailPermissions,
} from '@src/api/officeSuiteProvider'
import { Statuses } from '@src/interfaces'
import { EmployeeOnboardingSettingsInterface } from '@src/interfaces/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { Switch } from '@components/Inputs/Switch/Switch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  mapAccountStatusToProps,
  useShowOfficeSuitePermissionsError,
} from '@src/utils/linkedAccounts'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const EmailIntegration = () => {
  const { values } = useLapeContext<EmployeeOnboardingSettingsInterface>()
  const { data: employeePersonalSettings } = useGetEmployeePersonalSettings()
  const isAccountLinked = !!values.contact_hr_email
  const isEmailIntegrationEnabled = !!employeePersonalSettings?.email_integration_enabled
  const isGoogle = employeePersonalSettings?.office_suite_provider === 'google'

  useShowOfficeSuitePermissionsError()
  const showStatusPopup = useShowStatusPopup()

  const onClickSyncEmails = async () => {
    if (isAccountLinked) {
      try {
        await unlinkContactHrEmailAccount()
        values.contact_hr_email = null
        values.contact_hr_display_name = null
      } catch (e) {
        showStatusPopup({
          title: 'Something went wrong',
          description: getStringMessageFromError(e),
          status: 'error',
        })
      }
    } else if (isGoogle) {
      await askGoogleEmailPermissions('employee_onboarding_settings')
    } else {
      await askMicrosoftEmailPermissions('employee_onboarding_settings')
    }
  }

  if (!employeePersonalSettings?.office_suite_provider) {
    return null
  }

  const { color, statusIcon, statusText } = mapAccountStatusToProps(
    isAccountLinked ? Statuses.linked : Statuses.pending,
  )
  const title = isAccountLinked
    ? values.contact_hr_email
    : isGoogle
    ? 'Gmail'
    : 'Microsoft Email'

  return (
    <VStack gap="s-16">
      {!isEmailIntegrationEnabled && (
        <ActionWidget
          avatarColor={Token.color.grey20}
          title="Please connect an email provider before linking your account"
        />
      )}
      <Item
        disabled={!isEmailIntegrationEnabled}
        onClick={onClickSyncEmails}
        use="button"
      >
        <Item.Avatar>
          <Avatar useIcon={isGoogle ? LogoGoogle : LogoMicrosoft}>
            <Avatar.Badge
              bg={color}
              color={Token.color.background}
              useIcon={statusIcon}
            />
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description color={color}>{statusText}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Switch
            checked={isAccountLinked}
            disabled={!isEmailIntegrationEnabled}
            onChange={e => {
              e.stopPropagation()
              onClickSyncEmails()
            }}
          />
        </Item.Side>
      </Item>
      {isAccountLinked && (
        <LapeNewInput
          label="Onboarding team display name"
          name="contact_hr_display_name"
          description="This is the name displayed as the sender of the email to employees"
          required
        />
      )}
    </VStack>
  )
}
